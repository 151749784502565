import { Link } from "react-router-dom"; 
import { HelmetLayout } from "../../components/Layouts/components"; 

export const PageNotFound = () => {

  const title_content = "Error 404";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, Conductivity Bleed Control Cooling Towers, Conductivity Control Boilers, pH Control, pH Probes, pH Probe Holders, ORP Controllers, ORP Probes, ORP Probe Holders, Control Modules Pulse Splitters, Control Modules Pulse Coverters, Probes And Sensors, Add-On Modules, Manifolds and Bypass Assemblies, Buffer Solutions. Page Not Found";
  const cononical_content = "*";

  return (
    <>
    <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
    <main className="bg-white dark:bg-gray-900 mt-2 mb-2 rounded-lg">
      <section className="mt-32">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500 text-gray-500 dark:text-gray-400">404</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Oooops! Something went wrong.</p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                <Link to="/" className="inline-flex dark:text-white text-black bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4 border">Back to Home</Link>
            </div>   
        </div>
       </section>
    </main>
    </>
  )
}

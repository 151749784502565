import { Header, Footer } from "./components/index";
import { AllRoutes } from "./routes/AllRoutes";

function App() {
  return (
    <div className="bg-gray-200 dark:bg-gray-400 ">
        <Header/>
          <AllRoutes/>
        <Footer/>
    </div>
  );
}

export default App;

import Skeleton from "react-loading-skeleton";

export const MultiItemCarsouselSketeonLayout = () => {

  return (
         <div role="status" className="border rounded-lg p-6 h-full animate-pulse bg-gray-400 dark:bg-gray-500 mr-1 ml-1">
            <div className="h-52 bg-slate-200 rounded-md">
                <div className="flex justify-center p-1">
                   <div className="h-48 w-full sm:w-96 bg-gray-400 dark:bg-gray-700 flex justify-center items-center rounded-lg border border-gray-200 m-1">
                         <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center mt-8">
               <div className="h-4 rounded-full dark:bg-gray-500 w-60 mb-4"><Skeleton/></div>
            </div>
            <div className="flex justify-center h-12 mt-2">
              <div className="h-4 rounded-full dark:bg-gray-500 w-96 mb-4"><Skeleton/></div>   
            </div>
        </div>
  )
}

import Skeleton from "react-loading-skeleton";

export const ProductCardMobileSkeletonLayout = () => {
  return (

        <div role="status" className="block md:hidden animate-pulse mt-4 bg-gray-400 dark:bg-gray-700 rounded-lg">
            
            <div className="">
                <div className="p-4">
                    <div className="flex justify-center items-center mt-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-72 mb-3"><Skeleton/></div>
                    </div>
                    <div className="flex justify-center items-center mt-1">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"><Skeleton/></div>
                    </div>
                    <div className="flex items-center justify-center w-full h-72 bg-gray-300 rounded mt-2 ">
                        <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                        </svg>
                    </div>
                 </div>
                 <div className="w-full h-2 dark:bg-gray-800 bg-sky-900"></div>
                 <div className="w-full bg-gray-400 dark:bg-gray-700 p-2">
                    <div className="h-2.5 rounded-full dark:bg-gray-700 w-48 mb-4"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                </div>
                 <div className="w-full h-2 dark:bg-gray-800 bg-sky-900"></div>
                 <div className="w-full bg-gray-400 dark:bg-gray-700 p-2">
                    <div className="h-2.5 rounded-full dark:bg-gray-700 w-32 mb-4"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                    <div className="h-2 rounded-full dark:bg-gray-700 max-w-[680px] mb-3"><Skeleton/></div>
                </div>
                <div className="w-full h-2 dark:bg-gray-800 bg-sky-900"></div>
                 <div className="w-full flex justify-between bg-gray-400 dark:bg-gray-700 p-2">
                    <div className="h-2.5  rounded-full dark:bg-gray-700 w-32 mb-4"><Skeleton/></div>
                    <div className="h-2.5  rounded-full dark:bg-gray-700 w-20 mb-4 pr-3"><Skeleton/></div>
                </div>
            </div>
        </div>
  )
}


import { useSearchParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { getSearchedProduct } from "../../Service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HelmetLayout } from "../../components/Layouts/components"; 



export const SearchPage = () => {


  const [products , setProduct] = useState([]);
  const [loading , setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const queryTerm = searchParams.get(`q`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getSearchedProduct(queryTerm);
        setProduct(data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed To Fetch Product Details - " + error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [queryTerm]); 


  const searchIcon = <FontAwesomeIcon className="h-24 p-4 text-gray-400" icon={faSearch} />

  const title_content = "Search";
  const description_content = "JHA Electronics browse for our products user the product search option";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa Product Search";
  const cononical_content = "/search";

  return (
    <>
     <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
    <main>
         <section className="w-full bg-slate-400 dark:bg-slate-600 p-3 rounded-lg">
          { loading ? (<p className="text-gray-800 dark:text-gray-300 font-semibold">Awaiting Searched Results....</p>) : (  <p className="text-gray-800 dark:text-gray-300 font-semibold">{products.length === 0 ? `No Results Found For '${queryTerm}'` : `Results For '${queryTerm}'`}</p> ) }
         </section>
         <section className="bg-white dark:bg-gray-900 mt-2 rounded-lg">
                 { loading ? (
                    <div className="relative items-center w-full h-72 block p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                      <h5 className="mb-2 text-xl font-bold tracking-tight dark:text-gray-300 text-gray-800 opacity-40">Proccessing Your Searched Query...</h5>
                      <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                          <svg aria-hidden="true" className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                          <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                   ) : (
                    <div className="mx-auto max-w-screen-xl">
                       <div className="mb-4 w-full text-center bg-sky-900 dark:bg-gray-800 rounded-lg">
                          <h2 className="text-2xl tracking-tight font-extrabold text-gray-300 dark:text-white p-2">Found Products Listed Below</h2>
                       </div> 
                      { products.length !== 0 ? (
                      <div className="flex flex-wrap justify-center"> 
                        { products.map((product) => (
                            <div key={product.id} className="items-center md:h-96 h-full bg-gray-200 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700 m-2 p-1 lg:w-5/12 w-full border">
                                <div className="bg-gray-50 h-full lg:w-96 w-full rounded-lg ">
                                    <div className="flex justify-center items-center sm:pt-12 pb-4">
                                      <img className="text-center rounded-lg sm:rounded-none sm:rounded-l-lg pt-8 pr-1 pl-1" src={product.image_path} width={product.image_width_search} height={product.image_height_search} alt={product.name} />
                                    </div>
                                </div>
                                <div className="p-5 lg:w-96 w-full">
                                    <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                                        <span>{product.display_name}</span>
                                    </h3>
                                    <p className="mt-3 mb-4 font-meduim text-gray-500 dark:text-gray-400 text-sm">{product.description}</p>
                                    <div className="flex justify-center items-center sm:mt-0">
                                        <Link to={`/category/product/${product.url_name}`}><span className="bg-gray-600 p-2 rounded-2xl text-gray-300 font-semibold text-sm">View Product</span></Link>
                                    </div>
                                </div>
                            </div> 
                            ))
                        }
                      </div> 
                      ) : (
                        <>
                          <div className="flex flex-wrap justify-center"> 
                             <p className="mt-10 mb-4 font-semibold dark:text-gray-300 text-gray-800 p-3 sm:text-base text-sm text-center">We are unable to find anything related to your searched query</p>
                          </div>
                          <div className="flex flex-wrap justify-center "> 
                            <span className="mb-6">{searchIcon}</span>
                          </div>
                        </>
                      )}
                  </div>
                   )} 
         </section>
    </main>
    </>
  )
}


/*
  {
                loading && 
              <div className="relative items-center w-full h-72 block p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-800 dark:text-white opacity-20">Proccessing Your Searched Query...</h5>
                <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                    <svg aria-hidden="true" className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
              }
*/
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getCategories } from '../../../Service';
import { MultiItemCarsouselSketeonLayout } from '../../../components/Elements/Skeletons';


export const MutiItemCarsousel = () => {

const [ categoriesList , setCategoriesList ] = useState(new Array(14).fill(false));
const checkCategoryStyling = [2, 6, 11, 13];

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCategories();
        setCategoriesList(data);
      } catch (error) {
        toast.error("Product Categories - " + error.message);
      }
    };
    fetchData();
  }, [] );


  return (
    <section className='dark:bg-slate-700 h-full pb-1 border-2  border-gray-400 bg-slate-100 rounded-lg mt-4'>
        <div>
           <h1 className="mb-2 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-3xl dark:text-white text-center mt-2 pt-3">Product Categories</h1>
        </div>
        <div className="w-full dark:bg-slate-500 h-1 rounded-lg mb-3 bg-sky-900"></div>
        { categoriesList.length === 0 && <div className="flex justify-center items-center"><p className="dark:text-amber-200 text-center text-amber-800 font-semibold">There are currently no product categories available</p></div>}
        <Carousel infinite={true} responsive={responsive} showDots={true} ssr={true}>
            { categoriesList.map((category, index) => (
                   category ? (
                    <div key={category.id} className="border rounded-lg p-6 h-full bg-white mr-1 ml-1">
                      <div className="h-52 bg-slate-200 rounded-md">
                          <div className="flex justify-center p-4">
                            {
                               checkCategoryStyling.includes(category.id) ? (
                                <img className="pt-8" src={category.image_path} alt={category.name} width={category.image_width} height={category.image_height}/> 
                              ) : (
                                <img className="" src={category.image_path} alt={category.name} width={category.image_width} height={category.image_height}/> 
                              )    
                            }
                          </div>
                      </div>
                      <div className='sm:h-24 h-14'>
                          <h2 className="mt-2 sm:text-xl text-md font-bold text-gray-700 text-center ">{category.name}</h2>
                      </div>
                      <div className="flex justify-center h-12 mt-2">
                          <Link className='w-full dark:bg-slate-700 bg-sky-900 text-center rounded-lg' to={`/categories/${category.id}/${category.name}`}><button className="px-6 py-3 rounded-md dark:bg-slate-700 bg-sky-900 dark:text-white text-gray-100 font-medium ">Read More</button></Link> 
                      </div>
                 </div>
                 ) : (
                   <MultiItemCarsouselSketeonLayout key={index} />
                 )
                ))
            }    
        </Carousel>
        <div className="w-full dark:bg-slate-500 h-1 rounded-lg mb-3 bg-sky-900 mt-3"></div>
    </section>
  )
}


import { HelmetLayout } from "../../components/Layouts/components"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsersLine} from '@fortawesome/free-solid-svg-icons'



export const ContactUsPage = () => {

  const standardUser = <FontAwesomeIcon className="h-24 p-4 text-gray-200" icon={faUser} />
  const multipleUser = <FontAwesomeIcon className="h-24 p-4 text-gray-200" icon={faUsersLine} />

  const title_content = "Contact Us";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, Conductivity Bleed Control Cooling Towers, Conductivity Control Boilers, pH Control, pH Probes, pH Probe Holders, ORP Controllers, ORP Probes, ORP Probe Holders, Control Modules Pulse Splitters, Control Modules Pulse Coverters, Probes And Sensors, Add-On Modules, Manifolds and Bypass Assemblies, Buffer Solutions. Contact Us";
  const cononical_content = "/contact-us";

  return (
    <>
    <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content} />
    <main>
      <div className="flex flex-wrap justify-center border-2 dark:bg-slate-300 bg-slate-50 p-2 rounded-lg">
         <div className="md:m-4 m-1 w-full dark:bg-slate-800 bg-sky-900 rounded-lg">
               <h1 className="mb-4 sm:pt-2 pt-4 sm:text-3xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">Get In Touch With Us</h1>         
         </div>
        <div className="w-full md:max-w-sm max-w-md bg-white border-2  border-gray-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-1">
            <div className="flex flex-col items-center pb-10 ">
                <span>{standardUser}</span>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">James Anthony</h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">MANAGING DIRECTOR</span>
                <div className="flex flex-col mt-4 md:mt-6">
                    <span className="font-medium text-gray-900 dark:text-white">Office: +27 21 949 1105 </span>
                    <span className="font-medium text-gray-900 dark:text-white">Mail: james@jhaelectronics.com </span>
                </div>
            </div>
        </div>
        <div className="w-full md:max-w-sm max-w-md bg-white border-2  border-gray-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-1">
            <div className="flex flex-col items-center pb-10">
                <span>{multipleUser}</span>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">JHA Electronics Office</h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">OFFICE SUPPORT</span>
                <div className="flex flex-col mt-4 md:mt-6">
                    <span className="font-medium text-gray-900 dark:text-white">Office: +27 21 949 1105 </span>
                    <span className="font-medium text-gray-900 dark:text-white">Mail:  info@jhaelectronics.com </span>
                </div>
            </div>
        </div>
        <div className="w-full md:max-w-sm max-w-md bg-white border-2  border-gray-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-1">
            <div className="flex flex-col items-center pb-10">
                <span>{standardUser}</span>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">Horatio Anthony</h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">SALES / FINANCIAL</span>
                <div className="flex flex-col mt-4 md:mt-6">
                    <span className="font-medium text-gray-900 dark:text-white"> Office: +27 21 949 1105</span>
                    <span className="font-medium text-gray-900 dark:text-white"> Mobile: +27 84 019 1579</span>
                    <span className="font-medium text-gray-900 dark:text-white">Mail: horatio@jhaelectronics.com </span>
                </div>
            </div>
         </div>
      </div>
     <div className="flex justify-center flex-col dark:bg-slate-800 p-2 bg-slate-50 border-2 rounded-lg mt-4">
        <div className=" w-full dark:bg-slate-500 bg-sky-900 rounded-lg">
               <h1 className="mb-4 sm:pt-2 pt-4 sm:text-3xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">Our Offices Location</h1>         
         </div>
        <div className="flex flex-col items-center mt-3">
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white text-center">Adminstration & Research and Development</h5>
                <span className="text-base text-gray-500 dark:text-gray-400">South Africa</span>
                <span className="text-base text-gray-500 dark:text-gray-400">2 Clyde Street</span>
                <span className="text-base text-gray-500 dark:text-gray-400">Oakdale Bellville</span>
                <span className="text-base text-gray-500 dark:text-gray-400">Cape Town</span>
                <span className="text-base text-gray-500 dark:text-gray-400">7530</span>
        </div>
        <div className="relative w-full h-96 p-4 mt-4 border-2">
            <iframe className="absolute top-0 left-0 w-full h-full"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1655.9975995459492!2d18.636197152216226!3d-33.88977748533362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1b81cbc0f80c3c7a!2sJHA%20ELECTRONICS!5e0!3m2!1sen!2sza!4v1615815303285!5m2!1sen!2sza"
                frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" title="test">
            </iframe>
        </div>
     </div>
    </main>
    </>
  )
}

import { Link } from "react-router-dom";

export const HandleResponceLayout = () => {
  return (
    <div className="grid grid-row-2 h-screen place-items-center dark:bg-gray-900 p-4">
       <div>
        <p className="font-semibold text-xl dark:text-gray-300 text-gray-600 text-center">Whooops! Something went wrong while processing the request</p>
        <div className="flex justify-center align-bottom mt-5">
            <Link to="/" className="bg-sky-900 dark:bg-slate-800 inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-100 hover:text-gray-800 rounded-lg sm:border border-2 border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                Go Back To Home
            </Link>
        </div>
      </div>
    </div>
  )
}

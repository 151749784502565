import { createContext, useContext} from "react";
import  ProductsData from "../assets/data/products_data.json";
import HomeProductData from "../assets/data/home_products_data.json";

const products_category_list = ProductsData;  
const home_products_list = HomeProductData;

const productsInitialState = {
    productCategoryList: products_category_list,
    homeProductsList: home_products_list
}

const ProductContext = createContext(productsInitialState);

export const ProductProvider = ({children}) => {

   const value = {
         productCategoryList: products_category_list,
         homeProductsList: home_products_list
    }


    return (
         <ProductContext.Provider value={value}>
             {children}
         </ProductContext.Provider>
    )
}

export const useProduct = () => {
    const context = useContext(ProductContext);
    return context;
}
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { MobileScreenNavSkeletonLayout } from "../../Elements/Skeletons/index";

export const MobileCategorySubNav = ({productCategoryList}) => {

  const [hideCategorySubNav, setHideCategorySubNav] = useState(null);

  const handleCategorySubNav = (id) => {

        setHideCategorySubNav(hideCategorySubNav === id ? null : id)   
  }

  

  return (
    <div className="px-3 pt-2 pb-4 bg-sky-700 dark:bg-gray-700 shadow-lg mt-1 rounded-lg">
        <div className="grid grid-cols-1">
            <ul className="flex flex-col gap-2">
              <div className="border-gray-200 border-b-2">
                <NavLink to="/products" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" : "block py-2 px-1 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" }>View All Products</NavLink>
              </div>
              { productCategoryList.map((category, index) => (
                  category ? (
                   <li onClick={() => handleCategorySubNav(category.id)} className="border-b-2 pb-2 text-gray-300 dark:text-cyan-500" key={category.id}> {category.name}
                    <ul key={category.id} className={hideCategorySubNav === category.id ? "" : "hidden"} >
                        {
                            category.products.map((product) => (
                            <li className="ml-6 text-sm pt-1 pb-1 dark:text-zinc-200" key={product.id}>
                               <NavLink to={`category/product/${product.url_name}`} className={({ isActive }) => isActive ? "w-full block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" : "w-full block py-2 px-3 text-gray-400 rounded hover:bg-gray-700 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" }>{product.name}</NavLink>
                            </li>
                        )) 
                        }
                    </ul>
                  </li>
                  ) : (
                    <MobileScreenNavSkeletonLayout key={index} />
                  )
                ))}
           </ul>
        </div>
    </div>
  )
}

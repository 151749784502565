import { Link } from "react-router-dom";
import { useState , useEffect } from "react";
import { getPdfDownloads } from "../../Service";
import { toast } from "react-toastify";
import { HelmetLayout } from "../../components/Layouts/components"; 
import { DownloadsBrochuresSkeletonLayout } from "../../components/Elements/Skeletons";
import PDFImage from "../../assets/Adobe_Acrobat_Reader_icon.svg";


export const DownloadsPage = () => {

  const [ productBrochures , setProductBrochures ] = useState([])
  const [loading , setLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPdfDownloads();
        setProductBrochures(data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed To Fetch Product Categories - " + error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [])


  const title_content = "Downloads Product Brochures";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, Conductivity Bleed Control Cooling Towers, Conductivity Control Boilers, pH Control, pH Probes, pH Probe Holders, ORP Controllers, ORP Probes, ORP Probe Holders, Control Modules Pulse Splitters, Control Modules Pulse Coverters, Probes And Sensors, Add-On Modules, Manifolds and Bypass Assemblies, Buffer Solutions. Product Brochures";
  const cononical_content = "/downloads";


  return (
    <>
    <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
    <main>
      <section>
          <div className="relative overflow-x-auto rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-500">
                      <tr>
                          <th scope="col " className="md:m-4 m-1 w-full dark:bg-slate-500 bg-sky-900">
                              <h1 className="mb-4 sm:pt-2 pt-4 sm:text-2xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">Product Brochures</h1>    
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                      loading ? (
                        <DownloadsBrochuresSkeletonLayout/>
                      ) : (
                        <>
                        { productBrochures.map((brochures, index) => (
                              brochures ? (
                               <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-2 py-4 sm:px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                  <div className="flex justify-between">
                                    <span className="mt-2 text-center text-sm  md:text-lg"><Link to={brochures.path} target="_blank">{brochures.name}</Link></span>
                                    <span className="invisible sm:visible"><Link to={brochures.path} target="_blank"><img src={PDFImage} alt="" className="w-8" /></Link></span>
                                  </div>
                                </th>
                               </tr>
                              ) : (
                               <tr key={index}  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                 { index === 0 &&
                                  <th scope="row" className="px-2 py-4 sm:px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <div className="flex justify-center ">
                                      <span className="mt-2 text-center font-bold text-lg">No Brochures Were Loaded</span>
                                    </div>
                                  </th>
                                   }
                               </tr>
                              )
                           ))}
                        </>
                      )
                    }
                  </tbody>
              </table>
          </div>
      </section>
    </main>
    </>
  )
}


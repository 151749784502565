
import { useParams } from "react-router-dom";
import { useEffect , useState } from "react";
import { toast } from "react-toastify";
import { getProductDetails } from "../../Service";
import { ProductCardMobileLayout , ProductCardStandardLayout } from "./components";
import { HelmetLayout } from "../../components/Layouts/components"; 
import { HandleResponceLayout } from "../../components/Elements/Responces";
import { ProductCardMobileSkeletonLayout, ProductCardStandardSkeletonLayout } from "../../components/Elements/Skeletons/index";


export const ProductDetailPage = () => {

    const [product, setProduct ] = useState([]);
    const [loading , setLoading] = useState(false);
    const { name } = useParams();
   
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const data = await getProductDetails(name);
          setProduct(data);
          setLoading(false);
        } catch (error) {
          toast.error("Failed To Fetch Product Details - " + error.message);
          setLoading(false);
        }
      };
      fetchData();
    }, [name]); 


  return (
    <>
    {
       product.length !== 0 &&  <HelmetLayout title_content={product[0].name} description_content={product[0].description} keywords_content={product[0].display_name} cononical_content={`/category/product/${name}`}/>
    }
    <main> 

            <div>
            {
              loading ? (
               <div>
                   <ProductCardStandardSkeletonLayout/>
                   <ProductCardMobileSkeletonLayout />
               </div>
              ) : (
               <>
                { 
                 product.length !== 0 ? (
                     <div>
                         <ProductCardStandardLayout product={product}/>
                         <ProductCardMobileLayout product={product}/>
                     </div> 
                     ) : (
                        <HandleResponceLayout/>
                     )
                 } 
               </>
              )
            }
         </div>
    </main>
    </>
  )
}



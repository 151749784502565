
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

export const CarouselBanner = () => {


 const slides_list = [
        {'slide_id': 0, 'slide_image': './assets/images/banner_images/banner_digichemplus.png', "slide_letter": "J"},
        {'slide_id': 2, 'slide_image': './assets/images/banner_images/banner_various_controllers.png', "slide_letter": "H"},
        {'slide_id': 1, 'slide_image': './assets/images/banner_images/banner_site.png', "slide_letter": "A"}
      ] 
 
 const is_active_slide = "w-8 h-8 rounded-full text-white dark:text-white bg-sky-700 dark:bg-sky-700 cursor-pointer sm:mb-0 font-medium mr-2";
 const not_active_slide = "w-8 h-8 rounded-full bg-slate-200 dark:bg-stone-700 cursor-pointer sm:mb-0 dark:text-white font-medium mr-2";


  return ( 
    <div className="max-w-[1400px] h-60 w-full m-auto pt-1 px-1 relative mb-10 md:mb-7 md:h-96 lg:h-[380px]">
     <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} showThumbs={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
            return (
            <button type="button" onClick={clickHandler} className={`flex ${hasPrev ? "absolute" : "hidden"} top-0 start-0 z-30  items-center justify-center h-full px-4 cursor-pointer group focus:outline-none`} data-carousel-prev>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                    </svg>
                </span>
            </button>
            );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
            return (
            <button type="button" onClick={clickHandler} className={`flex ${hasNext ? "absolute" : "hidden"} top-0 end-0 z-30 items-center justify-center h-full px-4 cursor-pointer group focus:outline-none`} data-carousel-next>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                    </svg>
                </span>
            </button>
            );
        }}
        renderIndicator={(onClickHandler, isSelected, index) => {
            return (
               <button key={index} onClick={onClickHandler} type="button" className={ isSelected ? is_active_slide : not_active_slide} aria-current="true" aria-label={`Slide ${index}`}>
                 <p>{ index === 0 && "J"} { index === 1 && "H"} { index === 2 && "A"}</p>
               </button>
            ); 
          }}
     >
        
     {
        slides_list.map((slide) => ( 
            <div key={slide.slide_id} className=" h-64  md:h-96 rounded-lg ">
                <div style={{backgroundImage:`url(${slide.slide_image})`}} className='w-full h-full bg-center bg-cover border-2 border-black rounded-lg'></div>
           </div>
        )) 
      }        
    </Carousel>
  </div>
  )
}




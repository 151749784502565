import { useParams , Link} from "react-router-dom";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { getCategoryProducts } from "../../Service";
import { HelmetLayout } from "../../components/Layouts/components";
import { HandleResponceLayout } from "../../components/Elements/Responces";
import { CategoryProductsSkeletonLayout } from "../../components/Elements/Skeletons"


export const ProductCategoryPage = () => {

  const [ categoryProducts, setCategoryProducts ] = useState([]);
  const [loading , setLoading] = useState(false);

  const { id , name } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getCategoryProducts(id) 
        setCategoryProducts([data]);
        setLoading(false);
      } catch (error) {
        toast.error("Failed To Fetch Categories Products - " + error.message);
        setLoading(false);
      } 
    };
    fetchData();
  }, [id]);


  return (
    <>
    { 
      categoryProducts.length !== 0 && <HelmetLayout title_content={categoryProducts[0].name} description_content={categoryProducts[0].description} keywords_content={`JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, ${categoryProducts[0].description}`} cononical_content={`/categories/${id}/${name}`}/> 
    } 
    <main>
        { loading ? (
            <CategoryProductsSkeletonLayout/>
        ) : (
          <>
          {
              categoryProducts.length !== 0 ? (
              <div className="border-2 rounded-lg m-2 bg-slate-100">
                { categoryProducts.map((category) => (
                <div key={category.id}>      
                  <div className="flex flex-col">
                    <div  className=" w-full dark:bg-slate-500 bg-sky-900 rounded-lg">
                      <h1 className="mb-4 sm:pt-2 pt-4 pr-2 pl-2 sm:text-3xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">{category.name}</h1>         
                    </div>
                  </div>
                  { category.products.map((product) => (
                    <div key={product.id} className="border-2 m-1 rounded-lg p-2 dark:bg-slate-700">
                      <div className="flex flex-row sm:flex-nowrap flex-wrap">
                          <div className="h-65 w-full sm:w-96 bg-slate-100 flex justify-center rounded-lg border border-gray-200 m-1">
                              <Link to={`/category/product/${product.url_name}`} className=" text-center"><span className="p-3"><img src={product.image_path} alt="" width={product.image_width} height={product.image_height} /></span></Link>
                          </div>
                          <div className="h-65 w-full bg-slate-100 flex justify-center rounded-lg border border-gray-200 m-1 flex-col">
                              <Link to={`/category/product/${product.url_name}`} className="p-3 text-center"><span className="p-3 text-center font-bold text-lg">{product.display_name}</span></Link>
                              <span className="p-3 mt-2 text-center">{product.description}</span>
                              <div className="flex justify-center"><Link type="button" to={`/category/product/${product.url_name}`} className="bg-sky-600 hover:bg-sky-900 dark:bg-gray-700 hover:dark:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mb-2 sm:mb-1">View Product</Link></div>
                          </div>
                      </div>
                    </div>
                    ))}
                  </div>   
                ))}
            </div>
              ) : (
                <HandleResponceLayout/>
              )
            }
            </>
        )}
    </main>
    </>
  )
}



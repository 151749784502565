import axios from "axios";
 
export async function getNavigation(){
    const response = await axios.get(`${process.env.REACT_APP_API_GET_NAVIGATION}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
        throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;    
}


export async function getCategories(){
    const response = await axios.get(`${process.env.REACT_APP_API_GET_CATEGORIES}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
        throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;
}


export async function getCategoryProducts(category_id){
    const response = await axios.get(`${process.env.REACT_APP_API_GET_CATEGORY_PRODUCTS}${category_id}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
        throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;     
}


export async function getProductDetails(product_name){
    const response = await axios.get(`${process.env.REACT_APP_API_GET_PRODUCT_DETAIL}${product_name}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
        throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;    
}


export async function getRecommendedProducts(){
    const response = await axios.get(`${process.env.REACT_APP_API_GET_RECOMMENDED_PRODUCTS}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
         throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;    
}

export async function getPdfDownloads() {
    const response = await axios.get(`${process.env.REACT_APP_API_GET_PDF_DOWNLOADS}`, { headers:{ Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`} });
    if(response.status !== 200){
        throw { message: response.statusText , status: response.status } //eslint-disable-line 
    } 
    const data = response.data;
    return data;  
}
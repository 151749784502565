import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState , useEffect} from "react";
import { toast } from "react-toastify";
import Logo from "../../assets/staticLogoIcon.ico";
import '../../../src/index.css';
import { getNavigation}  from '../../Service/index';
import { DarkModeToggle, MobileCategorySubNav, CategoryProductsList } from "./components";




export const Header = () => {

  //__Handling The Drop Down Menu Section
  const [dropDownMenu, setDropDownMenu] = useState(false);
  
  //__Handling The Sub Drop Down Menu Section
  const [hideCategoryNav, setHideCategoryNav ] = useState(false);
 
  const [ productCategoryList, setProductCategoryList ] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNavigation()
        setProductCategoryList(data);
      } catch (error) {
        toast.error("Failed To Fetch Navigation Information - " + error.message);
      }
    };
    fetchData();
  }, [] );

  const navigate = useNavigate();

  const handleSearchSubmit = (event) => {
      event.preventDefault();
      const queryTerm = event.target.search.value; 
      if (queryTerm !== ""){
        event.target.reset();
        return navigate(`/search?q=${queryTerm}`);
      }
      
  } 

  return (
    <header>
      <nav className="bg-sky-900 border-b-2 border-slate-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logo} className="h-24" alt="JHA Electronics Website" />
        </Link>
        <div className="flex md:order-2  flex-wrap items-center justify-between nav-search-position:ml-0 sm:ml-72 ml-0 ">
          { <DarkModeToggle /> }
          <button onClick={() => setDropDownMenu(!dropDownMenu)} type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 mr-2">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
            <span className="sr-only">Search</span>
          </button>

          <div className="relative hidden md:block  nav-search-position:w-56 sm:w-96 w-0">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
              <span className="sr-only">Search icon</span>
            </div>
            <form onSubmit={handleSearchSubmit}>
               <input type="text" id="search-navbar" name="search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " placeholder="Search..." />
            </form>
          </div>

          <button onClick={() => setDropDownMenu(!dropDownMenu)} data-collapse-toggle="navbar-search" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-search" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
              </svg>
          </button>
        </div>
          <div className={ dropDownMenu ? "items-center justify-between w-full md:flex md:w-auto md:order-1" : "items-center justify-between hidden w-full md:flex md:w-auto md:order-1"} id="navbar-search">
            <div className="relative mt-3 md:hidden">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
              </div>
              <form onSubmit={handleSearchSubmit}>
                 <input type="text" id="search-navbar" name="search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." />
              </form>
            </div>
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-sky-900 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink to="/" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-grey-50 md:p-0 md:dark:text-blue-500" : "block py-2 px-3 text-gray-300 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" } aria-current="page" end>Home</NavLink>
              </li>
              <li>
                <div className="flex flex-row">
                    { /* Standard Screen Drop Down Catgegory Nav */ }
                    <div className="dropdown inline-block relative w-full">
                        <span className="w-full block py-2 px-3 text-gray-300 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Products</span>
                        {<CategoryProductsList productCategoryList={productCategoryList} />}
                    </div>
                    <div className="relative md:hidden">
                        <div onClick={() => setHideCategoryNav(!hideCategoryNav)} className="absolute top-1 right-1 h-8 w-8 bg-gray-500 rounded grid justify-items-center">{hideCategoryNav ? <div className="text-4xl">-</div> : <div className="text-2xl">+</div>}</div>
                    </div>
                </div>
                <div className={hideCategoryNav ? "md:hidden" : "hidden"}>
                      { /* Mobile Drop Down Catgegory Nav */
                        <MobileCategorySubNav  productCategoryList={productCategoryList} />
                      }
                 </div>
              </li>
              <li>
                <NavLink to="/downloads" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-grey-50 md:p-0 md:dark:text-blue-500" : "block py-2 px-3 text-gray-300 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" }>Downloads</NavLink>
              </li>
              <li>
                 <NavLink to="/about-us" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-grey-50 md:p-0 md:dark:text-blue-500" : "block py-2 px-3 text-gray-300 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" }>About</NavLink>
              </li>
              <li>
                 <NavLink to="/contact-us" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-grey-50 md:p-0 md:dark:text-blue-500" : "block py-2 px-3 text-gray-300 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-400 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" }>Contact Us</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}


import { NavLink } from "react-router-dom";
import { useState } from "react";
import { StandardScreenNavSkeletonLayout } from "../../Elements/Skeletons/index"; 

export const CategoryProductsList = ({productCategoryList}) => {

  const [hideActiveSubNav, setHideActiveSubNav] = useState(null);

  const handleActiveSubNav = (id) => {

       setHideActiveSubNav(hideActiveSubNav === id ? null : id)   
  }

  return (
    <ul className="z-40 dropdown-content absolute hidden text-gray-700 pt-1 w-96 lg:-ml-40 dropdown-nav-position-1:-ml-96 dropdown-nav-position-2:-ml-36 m:-ml-20 invisible md:visible border border-gray-400">
      <li>
          
          <NavLink to={`/products`}  className={({ isActive }) => isActive ? " bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-sm text-blue-700" : "bg-sky-700 text-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-sm dark:bg-gray-900 hover:dark:bg-gray-400" } >View All Products</NavLink>
      </li>
    { 
      productCategoryList.map((category, index) => (
         category ? (
          <li key={category.id} className="dropdown">
            <span className="dark:bg-gray-900 bg-sky-700 text-gray-200 hover:bg-gray-500 hover:dark:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-sm " >{category.name}</span>
            <ul className="dropdown-content absolute hidden text-gray-700 pl-1 ml-96 -mt-9 w-96 border border-gray-400">
              {
                category.products.map((product) => (
                  <li key={product.id} onClick={() => handleActiveSubNav(product.id)}>
                    <NavLink to={`category/product/${product.url_name}`}  className={({ isActive }) => isActive ? " bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-sm text-blue-700" : "bg-sky-700 text-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-sm dark:bg-gray-900 hover:dark:bg-gray-400" } >{product.name}</NavLink>
                  </li>
                  )) 
                }
           </ul>
         </li>
        ) : (
          <StandardScreenNavSkeletonLayout key={index}/>
        )
      ))
     }
    </ul>
  )
}

import Skeleton from "react-loading-skeleton";

export const StandardScreenNavSkeletonLayout = () => {

  return (
    <li role="status" className="animate-pulse bg-gray-600 dark:bg-gray-500  h-10 p-2">
        <span className="h-4 dark:bg-gray-500 w-full mb-4 bg-gray-600 mt-2"><Skeleton/></span>
    </li>
  )
}

import { Helmet } from 'react-helmet-async';

export const HelmetLayout = ({title_content = "", description_content = "", keywords_content = "", cononical_content = ""}) => {
  return (
    <Helmet>
        <title>JHA Electronics - {title_content}</title>
        <meta name="description" content={description_content} />
        <meta name="keywords" content={keywords_content}/>
        <link rel="canonical" href={cononical_content} />


        {
          //IF YOU DONT WANT PAGES TO BE CRAWLED TO OR INDEXED BY GOOGLE
          //<meta name="robots" content='noindex' />
        }
        
    </Helmet>
  )
}

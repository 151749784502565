import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { HelmetProvider } from 'react-helmet-async';
import { ProductProvider} from './context';
import { ScrollToTop } from './components/Other/ScrollToTop';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ProductProvider>
          <ToastContainer position={"bottom-right"} autoClose="3000" />
           <HelmetProvider>
             <ScrollToTop/>
             <App/>
           </HelmetProvider>
       </ProductProvider>
    </Router>
  </React.StrictMode>
);



import { Link, NavLink } from "react-router-dom";

export const Footer = () => {
  return (
      <footer className="bg-sky-900 dark:bg-gray-900 border-t-2 border-slate-200">
          <div className="w-full flex flex-col justify-center  mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between  sm:flex sm:flex-row sm:justify-between">
             <div className="flex flex-row justify-center sm:justify-end order-1 sm:order-none mt-3 sm:mt-0">
               <span className="text-sm sm:text-base text-gray-400 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} <Link to="/" className="hover:underline">JHA Electronics™</Link>. All Rights Reserved.</span>
            </div>
            <div className="flex flex-row justify-center sm:justify-end border-b dark:border-gray-700 border-gray-500 sm:border-none">
              <ul className="flex flex-wrap items-center text-base sm:text-base font-medium text-gray-400 dark:text-gray-400 sm:mt-0 mb-3">
                <li>
                    <NavLink to="/about-us" className={({ isActive }) => isActive ? "hover:underline me-16 md:me-6 sm:me-4 text-gray-300 dark:text-white" : "hover:underline me-16 md:me-6 sm:me-4" } end>About</NavLink>
                </li>
                 <li>
                    <NavLink to="/contact-us" className={({ isActive }) => isActive ? "hover:underline me-6 md:me-6 sm:me-4 text-gray-300 dark:text-white" : "hover:underline me-6 md:me-6 sm:me-4" }>Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
      </footer>
  )
}



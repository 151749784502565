import Skeleton from "react-loading-skeleton";

export const ProductCategorySkeletonLayout = () => {

  const skeletonProduct = new Array(14).fill(false);

  return (
    <div role="status" className="animate-pulse mt-4 bg-gray-400 dark:bg-gray-700 rounded-lg">
        <div className="flex flex-col">
            <div className="flex justify-center items-center mt-0 w-full dark:bg-slate-500 bg-sky-900 rounded-lg h-14">
                <div className="h-4 rounded-full dark:bg-slate-500 w-96 mb-2"><Skeleton height={20}/></div>
            </div>
        </div>
        { skeletonProduct.map((product, index) => (
            !product && 
            <div key={index} className="">
            <div className="flex flex-row sm:flex-nowrap flex-wrap">
                <div className="h-60 w-full sm:w-96 bg-gray-400 dark:bg-gray-700 flex justify-center items-center rounded-lg border border-gray-200 m-1">
                     <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                    </svg>
                </div>
                <div className="h-60 w-full bg-gray-400 dark:bg-gray-700 rounded-lg border border-gray-200 m-1">
                    <div className="flex justify-center items-center mt-14">
                        <div className="h-4 rounded-full dark:bg-gray-700 w-96 mb-4"><Skeleton/></div>
                    </div>
                    <div className="flex justify-center items-center mt-8">
                        <div className="h-4 rounded-full dark:bg-gray-700 w-60 mb-4"><Skeleton/></div>
                    </div>
                    <div className="flex justify-center items-center">
                        <div className="h-4 rounded-full dark:bg-gray-700 w-60 mb-4"><Skeleton/></div>
                    </div>
                </div>
            </div>
          </div>
         ))}
    </div>
  )
}

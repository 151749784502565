import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getRecommendedProducts } from "../../../Service";
import { MultiItemCarsouselSketeonLayout } from '../../../components/Elements/Skeletons';


export const Recommended = () => {

  const [ recommendedProducts , setRecommendedProducts ] = useState(new Array(3).fill(false)); //

  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRecommendedProducts();
        setRecommendedProducts(data);
      } catch (error) {
        toast.error('Recommended Products - ' + error.message);
      }
    };
    fetchData();
  }, []); 


  return (
    <div className="mt-4 border-2 rounded-lg bg-slate-100 border-gray-400 dark:bg-gray-800">
         <div>
           <h1 className="mb-3 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-3xl dark:text-white text-center mt-2 pt-3">Recommended Products</h1>
         </div>
         <div className="w-full dark:bg-slate-500 h-1 rounded-lg mb-3 bg-sky-900"></div>
         { recommendedProducts.length === 0 && <div className="flex justify-center items-center"><p className="dark:text-amber-200 text-center text-amber-800 font-semibold">There are currently no recommended products available</p></div>}
         <Carousel infinite={true} responsive={responsive} showDots={true} ssr={true}>
            { recommendedProducts.map((recommened, index) => (
                 recommened ? (
                 <div key={recommened.id} className="border rounded-lg p-6 h-full bg-white mr-1 ml-1">
                    <div className="h-52 bg-slate-200 rounded-md">
                        <div className="flex justify-center p-3">
                           <Link to={`category/product/${recommened.id}`}><img src={recommened.image_path} alt={recommened.name} width={recommened.image_width} height={recommened.image_height} /></Link>
                        </div>
                    </div>
                    <div className='sm:h-20 h-16'>
                        <h2 className="mt-2 sm:text-xl text-md font-bold text-gray-700 text-center ">{recommened.name}</h2>
                    </div>
                    <div className="flex justify-center h-12 mt-2">
                        <Link className='w-full dark:bg-slate-700 bg-sky-900 text-center rounded-lg' to={`category/product/${recommened.id}`}><button className="px-6 py-3 rounded-md dark:bg-slate-700 bg-sky-900 dark:text-white text-gray-100 font-medium ">View Product</button></Link> 
                    </div>
                </div>
                ) : (
                  <MultiItemCarsouselSketeonLayout key={index}/>
                ) 
                )) }    
        </Carousel>
        <div className="w-full dark:bg-slate-500 h-1 rounded-lg mb-4 mt-3  bg-sky-900"></div>
    </div>
  )
}

import { HelmetLayout } from "../../components/Layouts/components";
import { CarouselBanner , Jumbotron , NewReleases, Recommended, MutiItemCarsousel } from "./components";

export const HomePage = () => {

  const title_content = "Home Page";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = " JHA Electronics,JHA ,Cape Town, South Africa, Water Treatment, Purification, Digichem Plus, Add On Modules, ORP Controller, PH Controller, Conductivity Controller, Bleed Controllers, Buffer Solutions, Bleed Manifolds, Probes and Sensors";
  const cononical_content = "/";

  return (
    <>
     <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
     <main>
        <CarouselBanner/>
        <Jumbotron/>
        <NewReleases/>
        <Recommended/>
        <MutiItemCarsousel/>
     </main>
    </>
  )
}

import { Link } from "react-router-dom";
import { useProduct } from "../../../context";
import  pdfImage from "../../../assets/Adobe_Acrobat_Reader_icon.svg";

export const NewReleases = () => {

  const { homeProductsList } = useProduct();

  return (
    <div className="mt-4 border-2 rounded-lg bg-slate-100 border-gray-400 dark:bg-gray-800">
         <section>
          <div className="mx-auto max-w-screen-xl ">
             <h1 className="mb-3 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-3xl dark:text-white text-center pt-4">New Product Release</h1>
             <div className="w-full dark:bg-slate-500 h-1 rounded-lg mb-2 bg-sky-900"></div>
             <div>
                {
                    homeProductsList.new_products.map((new_product) => (
                        new_product.new_product_id === "1_01" && 
                        <div key={new_product.new_product_id} className="flex justify-center flex-wrap ">
                                <div className="w-full laptop:w-96 m-1 p-2 mr-0 lg:mr-40">
                                    <div>
                                        <div className="p-2 flex flex-col">
                                                <span className="dark:text-white text-black font-semibold text-xl pb-2 text-center">{new_product.new_product_name}</span>
                                                <span className="dark:text-white text-black text-center text-base sm:text-xl">{new_product.new_product_model}</span>
                                        </div>
                                        <div className="flex justify-center">
                                            <img src={new_product.new_product_image} alt={new_product.new_product_name} width={new_product.new_product_image_width} height={new_product.new_product_image_height} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full laptop:w-96  m-1 p-2">
                                    <div className="p-2 flex justify-start">
                                        <span className="dark:text-white text-black font-semibold text-lg sm:text-xl pb-2">Features :</span> 
                                    </div>
                                    <div className="p-1 flex justify-start">
                                       <ul className="list-disc pl-3">
                                          {
                                            new_product.new_product_features.map((features, index) => (
                                              <li className="dark:text-white text-sm sm:text-base" key={index}>{features}</li>
                                            ))
                                          }
                                       </ul>
                                    </div>
                                    <div className="p-2 flex justify-between mt-4">
                                        <span className="dark:text-white text-black font-semibold text-lg sm:text-xl pb-2">PDF :</span>
                                        <span><Link to={new_product.new_product_pdf} target="_blank"><img className="h-10 w-10" src={pdfImage} alt="downloads" /></Link></span>
                                    </div>
                                </div>
                        </div>
                    ))
                }
             </div>
             <div className="w-full dark:bg-slate-500 h-1 rounded-lg mt-2 mb-2 bg-sky-900"></div>
          </div>
        </section>
    </div>
  )
}

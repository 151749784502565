import { Link } from "react-router-dom";

export const Jumbotron = () => {
  return (
    <div className="mt-5 border-2 border-gray-400 rounded-lg">
     <section className="bg-slate-100 dark:bg-gray-900">
       <div className="py-6 px-4 mx-auto max-w-screen-xl text-center lg:py-6">
           <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Welcome to JHA Electronics</h1>
           <p className="mb-8 text-md sm:text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP & Dosing controllers and integrated dosing systems to the following water treatment industries: Cooling Towers, Potable Water, Waste Water, Boilers, Swimming Pools, Food & Beverage and Process Instrumentation. Complete systems for cooling towers, Effluent plants and many more are available. Contact us for more information. Enquire about our installation services.</p>
           <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
               <Link to="/products" className="bg-sky-900 dark:bg-slate-800 inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-100 hover:text-gray-800 rounded-lg sm:border border-2 border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                   View Our Products
               </Link>
               <Link to="/about-us" className="bg-sky-900  dark:bg-slate-800 inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-100 hover:text-gray-800 rounded-lg sm:border border-2 border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                   Learn More About Us
               </Link> 
               <Link to="/contact-us" className="bg-sky-900  dark:bg-slate-800 inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-100 hover:text-gray-800 rounded-lg sm:border border-2  border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                   Contact Us For Further Information
               </Link>
           </div>
        </div>
     </section>
   </div>
  )
}

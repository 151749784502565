import Skeleton from "react-loading-skeleton";

export const DownloadsBrochuresSkeletonLayout = () => {

  const skeletonBrochures = new Array(17).fill(false);

  return (
    <>
    { skeletonBrochures.map((brochure, index) => (
      !brochure && 
        <tr key={index} className="bg-gray-400 border-b dark:bg-gray-800 dark:border-gray-700 animate-pulse">
            <th scope="row" className="px-2 py-4 sm:px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <div className="flex justify-between">
                    <div className="h-2.5  rounded-full dark:bg-gray-800  w-32 mb-4"><Skeleton/></div>
                    <div className="h-2.5  rounded-full dark:bg-gray-800 w-20 mb-4 pr-3"><Skeleton/></div>
                </div>
            </th>
        </tr>
     ))}
    </>
  )
}

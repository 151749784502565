
import { Link } from "react-router-dom";
import { HelmetLayout } from "../../components/Layouts/components"; 
import FacebookImage from "../../assets/Facebook.png";
import LinkinImage from "../../assets/Linkedin.png";

export const AboutUsPage = () => {


  const title_content = "About Us";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, Conductivity Bleed Control Cooling Towers, Conductivity Control Boilers, pH Control, pH Probes, pH Probe Holders, ORP Controllers, ORP Probes, ORP Probe Holders, Control Modules Pulse Splitters, Control Modules Pulse Coverters, Probes And Sensors, Add-On Modules, Manifolds and Bypass Assemblies, Buffer Solutions. About Us";
  const cononical_content = "/about-us";

  return (
    <>
    <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
    <main>
      <section className="bg-slate-100 dark:bg-gray-900 rounded-lg border-2 border-gray-300">
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center ">
             <div className=" w-full dark:bg-slate-800 bg-sky-900 rounded-lg">
                <h1 className="mb-4 sm:pt-2 pt-2 pb-2 sm:text-3xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">About JHA Electronics</h1>         
            </div>
              <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
                 JHA Electronics is a leading South African manufacturer, supplier and exporter of electronic controllers for the water treatment industry. Our products include conductivity, pH, ORP & Dosing controllers and integrated dosing systems. Typical applications are: Cooling Towers, Potable Water, Waste Water, Boilers, Swimming Pools, Food & Beverage and Process Instrumentation.
              </p>
              <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
                The company also offers comprehensive design, installation & maintenance services and after-sales support. Complete systems for cooling towers, Effluent plants and many more are available. With strong in-house engineering expertise, we furthermore partner with clients to develop bespoke electronic systems for industries as diverse as transportation telemetry and fruit sorting.
              </p>
              <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
                JHA Electronics believes in leveraging the advantages of the latest technological advances to deliver cost-effective, reliable products and solutions to our customers. We make liberal use of CAD / CAM, rapid prototyping and cloud computing technologies. Contact us for more information.
              </p>

              <div className="flex flex-row justify-center">
                   <span className="p-3 text-blue-500 font-extrabold mt-4"><Link to="https://www.facebook.com/Jha-Electronics-502445809963082" target="_blank">Facebook</Link></span>
                   <span className=""><Link to="https://www.facebook.com/Jha-Electronics-502445809963082" target="_blank"><img src={FacebookImage} alt="Facebook" className="w-20 p-1"/></Link></span>
                   <span className=""><Link to="https://www.linkedin.com/company/jha-electronics" target="_blank"><img src={LinkinImage} alt="Linkin"  className="w-20 p-1" /></Link></span>
                   <span className="p-3 text-blue-500 font-extrabold mt-4"><Link to="https://www.linkedin.com/company/jha-electronics" target="_blank">LinkedIn</Link></span>
              </div>
          </div>
      </section>
    </main>
    </>
  )
}

import { Link } from "react-router-dom";
import  pdfImage from "../../../assets/pdf.png";

export const ProductCardMobileLayout = ({product}) => {

  return (
        <div className="block md:hidden">
            {
            product.map((item) => (
            <div key={item.id} className="flex justify-center">
                <div className="w-full max-w-7xl bg-white border-2 border-gray-400 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="flex justify-center items-center mt-8">
                    <span className="text-xl sm:text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white pr-2 pl-2">{item.name}</span>
                </div>
                <div className="flex justify-center items-center mt-3">
                    <span className="text-base sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white">(<span className="dark:text-cyan-500 text-sky-700">{item.product_model}</span>)</span>
                </div>
                <div className="flex justify-center items-center">
                    <img className="p-6 rounded-t-lg" src={item.image_path} alt={item.name} />
                </div>
                <div className="px-4 pb-5 dark:bg-slate-600 bg-slate-100">
                    <div className="flex items-end pt-4">
                        <div><span className="text-gray-900 dark:text-slate-400 sm:text-base font-semibold">Features:</span></div>
                    </div>
                    <div className="flex items-end mt-2">
                            <ul className="list-disc pl-3">
                            {
                                item.product_features.map((feature, index) => <li key={index} className="text-gray-900 dark:text-white text-sm sm:text-sm pt-1"> {feature}</li>)
                            }
                            </ul>
                        </div>
                    </div>
                    
                    { item.product_options.length !== 0 &&
                        <div>
                        <div className="w-full h-2 dark:bg-gray-800 bg-sky-900"></div>
                        <div className="px-4 pb-5 dark:bg-slate-600 bg-slate-100">
                        <div className="flex items-end pt-4">
                            <div><span className="text-gray-900 dark:text-slate-400 sm:text-base font-semibold">Order Options:</span></div>
                        </div>
                        <div className="flex items-end mt-2 mb-0">
                                <ul className="list-disc pl-3">
                                {
                                    item.product_options.map((option, index) => <li key={index} className="text-gray-900 dark:text-white text-sm sm:text-sm pt-1"> {option}</li>)
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    }
                    {
                    item.pdf_path !== "-" &&  
                    <div>
                        <div className="w-full h-2 dark:bg-gray-800 bg-sky-900"></div>
                            <div className="px-4 pb-5 dark:bg-slate-600 bg-slate-100">
                            <div className="flex pt-4 justify-between">
                                <div><span className="text-gray-900 dark:text-slate-400 sm:text-base font-semibold">Download PDF:</span></div>
                                <div><Link to={item.pdf_path} target="_blank"><img className="h-14 w-12" src={pdfImage} alt="downloads" /></Link></div>
                            </div>
                        </div>
                    </div>   
                    }
                </div>
             </div> ))
            }
        </div>
  )
}


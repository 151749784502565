import { Routes, Route } from "react-router-dom";
import { HomePage, ProductsPage, ProductCategoryPage, ProductDetailPage, DownloadsPage, AboutUsPage, ContactUsPage, SearchPage, PageNotFound } from "../pages/index"; 

export const AllRoutes = () => {
  return (
       <Routes>
         <Route path="/" element={<HomePage />} />
         <Route path="/products" element={<ProductsPage />} />
         <Route path='/categories/:id/:name' element={<ProductCategoryPage/>}/>
         <Route path='/category/product/:name' element={<ProductDetailPage/>}/>
         <Route path="/downloads" element={<DownloadsPage />} />
         <Route path="/about-us" element={<AboutUsPage />} />
         <Route path="/contact-us" element={<ContactUsPage />} />
         <Route path="/search" element={<SearchPage />} />
         <Route path="*" element={<PageNotFound />} />
      </Routes>
  )
}

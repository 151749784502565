import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getCategories } from "../../Service";
import { HelmetLayout } from "../../components/Layouts/components";
import { HandleResponceLayout } from "../../components/Elements/Responces";
import { ProductCategorySkeletonLayout } from "../../components/Elements/Skeletons";


export const ProductsPage = () => {

  const [categories, setCategories] = useState([]);
  const [loading , setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getCategories();
        setCategories(data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed To Fetch Product Categories - " + error.message);
        setLoading(false);
      }
    };
    fetchData();
  },[]);

  
  const title_content = "Product Categories";
  const description_content = "JHA Electronics is a leading manufacturer, supplier and exporter of conductivity, pH, ORP and Dosing controllers and integrated dosing systems to the following ...";
  const keywords_content = "JHA Electronics, JHA, Cape Town, South Africa, Water Treatment, Purification, Conductivity Bleed Control Cooling Towers, Conductivity Control Boilers, pH Control, pH Probes, pH Probe Holders, ORP Controllers, ORP Probes, ORP Probe Holders, Control Modules Pulse Splitters, Control Modules Pulse Coverters, Probes And Sensors, Add-On Modules, Manifolds and Bypass Assemblies, Buffer Solutions.";
  const cononical_content = "/products";

  return (
    <>
    <HelmetLayout title_content={title_content} description_content={description_content} keywords_content={keywords_content} cononical_content={cononical_content}/>
    <main>
    { loading ? (
       <ProductCategorySkeletonLayout/>
       ) : (
      <>
      {
        categories.length !== 0 ? (
           <div className="border-2 rounded-lg m-2 bg-slate-100">
               <div className="flex flex-col">
                 <div className=" w-full dark:bg-slate-500 bg-sky-900 rounded-lg">
                    <h1 className="mb-4 sm:pt-2 pt-4 pr-2 pl-2 sm:text-3xl text-2xl font-extrabold tracking-tight leading-none text-gray-100  dark:text-white text-center">Product Categories</h1>         
                 </div>
               </div>
               {
                categories.map((category) => (
                 <div key={category.id} className="border-2 border-gray-400 m-1 rounded-lg p-2 dark:bg-slate-700">
                  <div className="flex flex-row sm:flex-nowrap flex-wrap">
                      <div className="h-65 w-full sm:w-96 bg-slate-200 flex justify-center rounded-lg border border-gray-200 m-1">
                         <Link to={`/categories/${category.id}/${category.name}`} className=" text-center"><span className="p-3"><img src={category.image_path} alt={category.name} width={category.image_width} height={category.image_height} /></span></Link>
                      </div>
                      <div className="h-65 w-full bg-slate-200 flex justify-center rounded-lg border border-gray-200 m-1 flex-col">
                         <Link to={`/categories/${category.id}/${category.name}`} className="p-3 text-center"><span className="p-3 text-center font-bold text-lg">{category.name}</span></Link>
                         <span className="p-3 mt-2 text-center">{category.description}</span>
                         <div className="flex justify-center"><Link type="button" to={`/categories/${category.id}/${category.name}`} className="bg-sky-600 hover:bg-sky-900 dark:bg-gray-700 hover:dark:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mb-2 sm:mb-1">View Category Products</Link></div>
                      </div>
                   </div>
                 </div> 
                 ))}
           </div>
        ) : (
          <HandleResponceLayout/>
        )
      }  
      </>
     )}
    </main>
    </>
  )
}

